import React from 'react'
import axios from 'axios'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import ContactForm from '../../components/form'

export default class ServiceDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const pageFullPath = this.props.location.pathname    
    const serviceUrl = pageFullPath.substring(
      pageFullPath.lastIndexOf('/') + 1
    )

    axios
      .get(`${process.env.API_URL}/umbraco/api/service/get?url=${serviceUrl}`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const { data } = this.state
    let content = ''
    let title = ''
    let photoUrl = ''

    if (data) {
      title = data.Name
      content = data.Description
      photoUrl = `${process.env.API_URL}${data.Photo}`
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Intervenções</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">              
              <div className="col-md-7 col-sm-12 col-xs-12">
                <div className="about_content">
                   <h1>{title}</h1>
                  <span dangerouslySetInnerHTML={{__html: content}} />
                </div>
              </div>
              <div className="col-md-5 col-sm-12 col-xs-12">
                <div className="about-slide">
                  <img src={photoUrl} />
                </div>
              </div>             
            </div>
          </div>
        </section>
      </div>
    )
  }
}
